import {
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Injector,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import { includes, isEmpty } from 'lodash';
import { BaseFormControlComponent } from '../base-form-control.component';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { isMobile, isUndefined } from 'src/app/utils/general.utils';
import { ControlsType } from 'src/app/constants/controls-type';
import { EventsService } from 'src/app/services/events.service';
@Component({
	selector: 'masked-input',
	templateUrl: './masked-input.component.html',
	styleUrls: ['./masked-input.component.scss'],
})
export class MaskedInputComponent extends BaseFormControlComponent implements OnInit, AfterViewChecked, AfterViewInit {
	@ViewChild('maskedInputForm', { static: true })
	maskedInputForm;
	el: ElementRef;
	hideTextColor: boolean;
	charsToRemove: string;
	inputPattern: string;
	inputMode: string;
	@Input() inputMask: string;
	@Input() SsnScriptUrl: string;
	@Input() placeholder: string;
	@Input() DynamicMobileKeyboard: any;
	@Input() title: string;
	@Input() type: any;
	isSafari: boolean;
	@Input() topPlaceholder: boolean;
	@Input() notshowLabelWhenFilled: boolean;

	constructor(
		injector: Injector,
		el: ElementRef,
		private cdRef: ChangeDetectorRef,
		protected store: Store<IAppState>,
		eventsService: EventsService
	) {
		super(injector, store, eventsService);
		this.el = el;
	}

	ngOnInit() {
		super.ngOnInit();
		this.value = null
		if (this.placeholder === '(     )     -') {
			this.placeholder = 'Phone number';
		}

		if (isUndefined(this.topPlaceholder)) {
			this.topPlaceholder = true;
		}
		this.controlType = ControlsType.maskedInput;
		this.hideTextColor = false;

		this.isMobile = isMobile();

		const inputControl = this.el.nativeElement.querySelector('.masked-input');

		if (inputControl) {
			const inputContext = this;
			inputControl.addEventListener('textInput', (event) => {
				const char = event.data;
				const keyCode = char.charCodeAt(0);

				if (inputContext.inputMask === '**/**/9999' && inputContext.value && inputContext.value.indexOf('X') !== -1) {
					this.value = '';
					return true;
				}
			});
		}

		if (this) {
			if (this.isMobile && this.DynamicMobileKeyboard) {
				this.inputPattern = '\\d*';
				this.inputMode = 'numeric';
			}
		}

		if (this.name === 'SSN') {
			this.injectSsnScript();
		}
	}

	ngAfterViewInit(): void {
		super.ngAfterViewInit();
	}

	ngAfterViewChecked(): void {
		this.cdRef.detectChanges();
	}

	injectSsnScript() {
		const temp = this.renderer.createElement('span');
		temp.innerHTML = "<a id='siteseal' style='width: 120px; height: 27px; margin-left:16px'></a>";
		this.renderer.appendChild(this.el.nativeElement.querySelector('.input-container'), temp);
		const script = this.renderer.createElement('script');
		script.src = this.SsnScriptUrl;
		this.renderer.appendChild(document.head, script);
		if (this.cssClass) {
			this.renderer.addClass(this.elementRef.nativeElement, this.cssClass);
		}
		setTimeout(() => {
			const el = this.el.nativeElement.querySelector('#siteseal');
			if (el.children && el.children[0]) {
				el.children[0].tabIndex = 0;
			}
			el.addEventListener('keypress', (event) => {
				event.preventDefault();
				el.children[0].onclick();
			});
		}, 500);
	}

	maskConfigs() {
		return this.getmask();
		// if (this.inputMask === '99/99/9999' || this.inputMask === '**/**/9999') {
		// 	return this.getmask() ;
		// } else {
		// return {
		// 	mask: this.getmask(),
		// 	placeholderChar: this.setPlaceholderChar(),
		// 	guide: true,
		// };
		// }
	}

	getmask() {
		switch (this.inputMask) {
			case '999-999-9999':
				return "000-000-0000";
			case '**/**/9999':
				return "AA/AA/0000";
			case '***-**-9999':
				return "AAA-AA-0000";
			case '99/99/9999':
				return "00/00/0000";
			case '99/9999':
				return "00/0000";
			case '99/99':
				return "00/00";
			case '9999 9999 9999 9999':
				return "0000 0000 0000 0000";
			default:
				return false;
		}
	}

	setPlaceholderChar() {
		switch (this.inputMask) {
			case '999-999-9999':
			case '***-**-9999':
				return '_';
			case '**/**/9999':
			case '99/99/9999':
				return '_';
			default:
				return '_';
		}
	}

	updateAnswer(event, isBlur: boolean) {
		if (isBlur) {
			super.baseOnBlur();
		}
		this.value = this.controlContainer.control.controls[this.name].value;
		// clean special characters if corresponding key exist
		const strippedValue = this.stripChars(this.value, this.charsToRemove).trim();
		this.hideTextColor = this.value === this.placeholder;

		this.value = strippedValue;
		const field = this.controlContainer.control.controls[this.name];
	}

	stripChars(value, charsToRemove) {
		if (typeof value === 'undefined' || value === null) {
			return '';
		}

		return !isEmpty(this.charsToRemove)
			? value
				.split('')
				.filter((char) => charsToRemove.indexOf(char) === -1)
				.join('')
			: value;
	}

	unSelectText() {
		super.baseOnFocus();

		if (window.getSelection) {
			window.getSelection().removeAllRanges();
		}
		const activeElement = document.activeElement;

		if (activeElement) {
			const tagName = activeElement.nodeName.toLowerCase();
			if (
				tagName === 'textarea' ||
				(tagName === 'input' && activeElement.getAttribute('type') === 'text') ||
				(tagName === 'input' && activeElement.getAttribute('type') === 'tel')
			) {
				(activeElement as HTMLInputElement).selectionStart = (activeElement as HTMLInputElement).selectionEnd;
			}
		}
	}

	setCarret($event) {
		let caretPos = 0;
		const elem = $event.currentTarget;
		const currentPosition = this.getCaretPosition(elem);
		const allowedChars = ['-', '(', ')', '/', ':'];

		// Not first position, calculate caret position
		if (currentPosition > 0 && this.value) {
			let valueChars = [];
			let maskChars = [];
			const splitValue = this.value.substring(0, currentPosition);

			// Have value and have placeholder in string from begin to caret position
			if (this.value && splitValue && splitValue.length > 0 && this.isPlaceholderExists(splitValue)) {
				valueChars = this.value.split('');
				maskChars = this.placeholder ? this.placeholder.split('') : [];

				// Calculate end of string for caret jump
				for (let i = 0; i <= maskChars.length; i++) {
					if (!includes(allowedChars, valueChars[i]) && (valueChars[i] === maskChars[i] || valueChars[i] === '_')) {
						caretPos = i;
						break;
					}
				}
			} else if (!splitValue || splitValue.length === 0) {
				caretPos = 0;
			} else {
				caretPos = currentPosition;
			}
		}

		if (elem != null) {
			if (elem.createTextRange) {
				const range = elem.createTextRange();
				range.move('character', caretPos);
				range.select();
			} else {
				if (elem.selectionStart) {
					elem.focus();
					elem.setSelectionRange(caretPos, caretPos);
				} else {
					elem.focus();
				}
			}
		}
		if (this.inputMask === '99/99/9999' || this.inputMask === '**/**/9999') {
		}
	}

	isPlaceholderExists(value) {
		const placeholderChars = ['·', 'X', 'M', 'D', 'Y', '_', ' '];
		let result = false;

		// If have value then check
		if (value && value.length > 0) {
			placeholderChars.forEach((character) => {
				if (value.indexOf(character) !== -1) {
					result = true;
					return;
				}
			});
		} else {
			result = true;
		}

		return result;
	}

	getCaretPosition(elem) {
		let caretPos = 0;

		if (elem.selectionStart || elem.selectionStart === '0') {
			caretPos = elem.selectionStart;
		}

		return caretPos;
	}

	keypadClose() {
		this.el.nativeElement.querySelector('.masked-input').blur();
	}
}
